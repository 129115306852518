export default {
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    text: "#444",
    background: "#efefef",

    mustard: "#fdbb2d",
    teal: "#22c1c3",
  },
  fonts: {
    body: "'IBM Plex Mono', monospace",
    heading: "'IBM Plex Mono', monospace",
    monospace: "'IBM Plex Mono', monospace",
  },
  fontWeights: {
    body: 200,
    heading: 600,
    bold: 500,
  },
};
